import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Hero } from "../components/hero"

export default function Designer() {
  return (
    <Layout>
      <Hero image="https://static.ateros.fr/32zD6EFqvgHemV7RhunGo5NQcPbBjk.png">
        <div className="badge badge-pill border border-light text-light">Services</div>
        <h1>Ateros Designer</h1>
      </Hero>

      <section id="content">

        <div className="content-wrap py-0 overflow-visible">
          <div className="card mx-auto mw-md border-0 shadow rounded-xl card-seo-about mb-5 mb-lg-0">
            <div className="card-body p-5">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="heading-block border-bottom-0 mb-0">
                    <h3 className="nott ls0 mb-3">Un outil indispensable.</h3>
                    <p>De nos jours la personnalisation de produit en ligne est essentielle pour une entreprise de
                      marquage.
                      Dotez vous d'un outil puissant et moderne
                      pour laisser parler la créativité de vos clients.</p>
                    <a href="#" className="button button-rounded text-capitalize m-0 ls0">Présentation</a>
                  </div>
                </div>
                <div className="col-md-6">
                  <StaticImage src="https://static.ateros.fr/GNOLxE3MR6oAHjJku72fdDBrUsiKP1.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="section bg-transparent">
            <div className="container">
              <div className="heading-block border-bottom-0 center">
                <div className="badge badge-pill badge-default">Présentation</div>
                <h3 className="nott ls0">Notre service</h3>
              </div>
              <div className="row col-mb-30 align-content-stretch">

                <div className="col-lg-4 col-md-6 d-flex">
                  <a href="" className="card h-shadow h-translate-y-sm all-ts">
                    <div className="card-body p-5">
                      <div className="feature-box flex-column">
                        <div className="fbox-image mb-5 text-center">
                          <StaticImage src="https://img.icons8.com/ios/100/000000/windows10-personalization.png" />
                        </div>
                        <div className="fbox-content">
                          <h3 className="nott ls0 text-larger">Personnalisation de produit</h3>
                          <p className="text-smaller">Un espace sur votre site web avec un éditeur en ligne. Ajoutez des
                            textes, images, clip art, modifiez vos design
                            et sauvegardez les simplement depuis une interface responsive.</p>
                        </div>

                        <ul className="iconlist ml-3 mt-4 mb-0">
                          <li className="mb-2 text-muted ls"><i className="fas fa-chevron-right"></i> Importation de
                            fichier
                          </li>
                          <li className="mb-2 text-muted ls"><i className="fas fa-chevron-right"></i> Ajout de texte /
                            images
                          </li>
                          <li className="mb-2 text-muted ls"><i className="fas fa-chevron-right"></i> Sauvegarde des
                            designs
                          </li>
                          <li className="mb-2 text-muted ls"><i className="fas fa-chevron-right"></i> Partage des
                            designs
                          </li>
                        </ul>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-lg-4 col-md-6 d-flex">
                  <a href="demo-seo-social.html" className="card h-shadow h-translate-y-sm all-ts">
                    <div className="card-body p-5">
                      <div className="feature-box flex-column">
                        <div className="fbox-image mb-5 text-center">
                          <StaticImage src="https://img.icons8.com/ios/100/000000/hanging-sign-in-window.png" />
                        </div>
                        <div className="fbox-content">
                          <h3 className="nott ls0 text-larger">Plateforme de vente</h3>
                          <p className="text-smaller">Objectively fashion highly efficient testing off proced through
                            sustainable channels.</p>
                        </div>
                        <ul className="iconlist ml-3 mt-4 mb-0">
                          <li className="mb-2 text-muted"><i className="icon-check text-smaller color"></i> Facebook
                            Advertising
                          </li>
                          <li className="mb-2 text-muted"><i className="icon-check text-smaller color"></i> Instagram
                            Advertising
                          </li>
                          <li className="mb-2 text-muted"><i className="icon-check text-smaller color"></i> Twitter
                            Advertising
                          </li>
                          <li className="mb-2 text-muted"><i className="icon-check text-smaller color"></i> Pinterest
                            Advertising
                          </li>
                          <li className="text-muted"><i className="icon-check text-smaller color"></i> Sales Banner</li>
                        </ul>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-lg-4 col-md-6 d-flex">
                  <a href="demo-seo-adwords.html" className="card h-shadow h-translate-y-sm all-ts">
                    <div className="card-body p-5">
                      <div className="feature-box flex-column">
                        <div className="fbox-image mb-5 text-center">
                          <StaticImage src="https://img.icons8.com/ios/100/000000/factory.png" />
                        </div>
                        <div className="fbox-content">
                          <h3 className="nott ls0 text-larger">Espace de production</h3>
                          <p className="text-smaller">Objectively fashion highly efficient testing off proced through
                            sustainable channels.</p>
                        </div>
                        <ul className="iconlist ml-3 mt-4 mb-0">
                          <li className="mb-2 text-muted"><i className="icon-check text-smaller color"></i> Google
                            Search
                          </li>
                          <li className="mb-2 text-muted"><i className="icon-check text-smaller color"></i> Google
                            Shopping
                          </li>
                          <li className="mb-2 text-muted"><i className="icon-check text-smaller color"></i> App Campaign
                          </li>
                          <li className="mb-2 text-muted"><i className="icon-check text-smaller color"></i> Video
                            Advertising
                          </li>
                          <li className="mb-2 text-muted"><i className="icon-check text-smaller color"></i> Mobile
                            Advertising
                          </li>
                          <li className="text-muted"><i className="icon-check text-smaller color"></i> Display
                            Advertising
                          </li>
                        </ul>
                      </div>
                    </div>
                  </a>
                </div>


              </div>
            </div>
          </div>
          <div className="section mt-5 mb-0"
               style={{padding: "100px 0", overflow: "visible", background: "url('https://static-cdn.ateros.fr/file/ateros/JhaxTlNV0Pg1QYfo9Uwndec0062MuMyl7SL3hUyn.png') no-repeat top center", backgroundSize: "cover"}}>
            <div className="container">
              <div className="heading-block border-bottom-0 center">
                <h5 className="text-uppercase ls1 mb-1">Démonstration et devis</h5>
                <h2 className="nott ls0">Une démonstration video ou un devis en <span
                  className="text-white">48h</span> !
                </h2>
                <a href="devis.php?source=Ateros-Designer"
                   className="button button-large button-rounded nott ml-0 ls0 mt-4">Contactez nous</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}